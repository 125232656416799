import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./resources@gen.scss";

const GeneratedResources = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>資料｜PagerDuty正規代理店（株）Digital Stacks</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="お役立ち資料や、eBookなどをご紹介しています。ぜひダウンロードください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="お役立ち資料や、eBookなどをご紹介しています。ぜひダウンロードください。"
        />

        <meta
          property="og:title"
          content="資料｜PagerDuty正規代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="お役立ち資料や、eBookなどをご紹介しています。ぜひダウンロードください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/resources/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/resources/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow=""
        textGreen="資料一覧"
        overview="お役立ち資料や、eBookなどをご紹介します。ぜひダウンロードください。<br />
PagerDuty製品に関するご質問は、[お問い合わせページ](/contact-jp/)よりお気軽にお問い合わせください。
"
      />

      <div className="resourcesWrapper">
        <div className="w-full px-[25px] md:px-0 pb-[180px]">
          <div className="bg-[#EEEEEE] pt-3 pb-[50px] w-fit">
            <div className="mx-10 mb-3 ">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/img_b70eab64bc.png?updated_at=2022-12-07T16:44:42.020Z"
                alt="Digital Operation"
                width="{320}"
                height="{320}"
                className="object-coverq md:w-[320px] md:h-[320px]"
              />
            </div>
            <div className="bg-[#BBBBBB] py-1 px-7 w-fit mb-8">eBook</div>
            <a href="/resources/state-of-digital-operations-2021/">
              <h3 className="text-xl md:text-2xl leading-8 text-[#4EB346] px-4 md:px-7 tracking-[3%] text-center">
                デジタルオペレーションの現状
              </h3>
            </a>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedResources;
